<template>
    <div>
        <h1 class="title is-4">
            <template v-if="$route.params.id">{{ $t("Interface.DoorProvider.Edit.Title") }}</template>
            <template v-else>{{ $t("Interface.DoorProvider.Add.Title") }}</template>
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <div class="columns is-multiline">
                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-full"
                    :name="$t('Interface.DoorProvider.Provider')"
                >
                    <b-field
                        :label="$t('Interface.DoorProvider.Provider')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-select v-model="Form.DoorProvider" expanded>
                            <option
                                v-for="AvailableProvider in AvailableProviders"
                                :key="AvailableProvider"
                                :value="AvailableProvider"
                            >
                                {{ AvailableProvider }}
                            </option>
                        </b-select>
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-if="Form.DoorProvider === 'Custom'"
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|min:10"
                    class="column is-full"
                    :name="$t('Interface.DoorProvider.Endpoint')"
                >
                    <b-field
                        :label="$t('Interface.DoorProvider.Endpoint')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Endpoint" type="text" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-if="Form.DoorProvider !== 'IronLogic'"
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.DoorProvider.PublicKey')"
                >
                    <b-field
                        :label="$t('Interface.DoorProvider.PublicKey')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.PublicKey" type="text" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-if="Form.DoorProvider !== 'IronLogic'"
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.DoorProvider.PrivateKey')"
                >
                    <b-field
                        :label="$t('Interface.DoorProvider.PrivateKey')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.PrivateKey" type="password" />
                    </b-field>
                </ValidationProvider>
            </div>

            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid"
                @click.prevent="handleSubmit(submit)"
            >
                {{ $t("Interface.Button.Add") }}
            </button>
        </ValidationObserver>
        <b-loading
            :is-full-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    DOORPROVIDER_LIST_REQUEST,
    DOORPROVIDER_ADD_REQUEST,
    DOORPROVIDER_EDIT_REQUEST
} from "@/store/actions/doorprovider"
import { IRONLOGIC_GENERATE_API_KEY_REQUEST } from "@/store/actions/ironlogic"
import { SYSTEM_STATUS_REQUEST } from "@/store/actions/system"

export default {
    name: "DoorProviderAdd",
    mixins: [LoadingState],
    data() {
        return {
            AvailableProviders: [
                "IronLogic",
                "Simplegate",
                "Ausweis",
                "Custom"
            ],
            Form: {
                DoorProvider: null,
                PublicKey: null,
                PrivateKey: null,
                Endpoint: null
            }
        }
    },
    computed: {
        DoorProvider() {
            return this.$store.getters.GET_DOORPROVIDER_BY_ID(parseInt(this.$route.params.id, 10))
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            if (!this.$store.getters.IS_DOORPROVIDERS_LOADED) {
                this.switchLoading()
                this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                    .then(() => this.getData())
                    .finally(() => this.switchLoading())
            }
            else this.getData()
        }
    },
    methods: {
        getData() {
            this.Form.DoorProvider = this.DoorProvider.doorProvider
            this.Form.PublicKey = this.DoorProvider.publicKey
            this.Form.PrivateKey = this.DoorProvider.privateKey
            this.Form.Endpoint = this.DoorProvider.endpoint
        },
        submit() {
            this.switchLoading()
            if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined" && this.Form.DoorProvider !== "IronLogic") {
                this.$store.dispatch(DOORPROVIDER_EDIT_REQUEST, { Form: this.Form, Id: this.$route.params.id })
                    .then(() => {
                        this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "DoorProvider-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
            else if (this.Form.DoorProvider === "IronLogic") {
                this.$store.dispatch(IRONLOGIC_GENERATE_API_KEY_REQUEST)
                    .then((response) => {
                        this.Form.PublicKey = this.$store.state.Account.Email
                        this.Form.PrivateKey = response.data.apiKey
                        this.$store.dispatch(DOORPROVIDER_ADD_REQUEST, this.Form)
                            .then(() => {
                                this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                                    .then(() => {
                                        this.$store.dispatch(SYSTEM_STATUS_REQUEST)
                                            .then(() => this.$router.push({ name: "DoorProvider-List" }))
                                    })
                            })
                            .finally(() => this.switchLoading())
                    })
            }
            else {
                this.$store.dispatch(DOORPROVIDER_ADD_REQUEST, this.Form)
                    .then(() => {
                        this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "DoorProvider-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
        }
    },
    metaInfo() {
        return {
            title: (this.$route.params.id) ? this.$t("Interface.DoorProvider.Edit.Title") : this.$t("Interface.DoorProvider.Add.Title")
        }
    }
}
</script>

<style>

</style>
